import * as React from 'react'

import { cn } from '#app/utils/misc.tsx'

export type InputProps = React.InputHTMLAttributes<HTMLInputElement>

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    const isInvalid = props['aria-invalid']
    return (
      <input
        type={type}
        className={cn(
          type == 'checkbox' ? 'form-check-input' : 'form-control',
          isInvalid ? 'is-invalid' : undefined,
          className,
        )}
        ref={ref}
        {...props}
      />
    )
  },
)
Input.displayName = 'Input'

export { Input }
