import * as React from 'react'

import { cn } from '#app/utils/misc.tsx'

export type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement>

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    const isInvalid = props['aria-invalid']
    return (
      <textarea
        className={cn(
          'form-control',
          isInvalid ? 'is-invalid' : undefined,
          className,
        )}
        ref={ref}
        {...props}
      />
    )
  },
)
Textarea.displayName = 'Textarea'

export { Textarea }
